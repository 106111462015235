<template>
  <div class="display">
    <div class="back" @click="$router.go(-1)">
      <i class="el-icon-arrow-left"></i>
    </div>
    <component
      v-if="templateType == 'birthday'"
      :is="Birthday"
      ref="template"
    ></component>
    <component
      v-if="templateType == 'commemoration'"
      :is="Commemoration"
      ref="template"
    ></component>
    <component
      v-if="templateType == 'loveLetter'"
      :is="LoveLetter"
      ref="template"
    ></component>
    <component
      v-if="templateType == 'newYear'"
      :is="NewYear"
      ref="template"
    ></component>
    <component
      v-if="templateType == 'valentine'"
      :is="Valentine"
      ref="template"
    ></component>
    <div class="bottom">
      <div class="name">
        <img src="@/assets/icon/templateIcon.png" alt="" />
        <span>{{ titleMap[templateType] }}</span>
      </div>
      <div class="btn" @click="gotoEdit">
        <img src="@/assets/icon/plane.png" alt="" />
        <span>立即制作</span>
      </div>
    </div>
    <audio
      :src="playingMusic"
      style="position: fixed; top: 0"
      autoplay
      ref="audio"
    ></audio>
    <el-tooltip
      effect="dark"
      content="点击播放音乐"
      placement="bottom-start"
      v-model="tip"
      manual
    >
      <div class="icon play" v-if="play" @click="togglePlay"></div>
      <div class="icon pause" v-else @click="togglePlay"></div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "Display",
  data() {
    return {
      Birthday: () => import("@/components/birthday"),
      Commemoration: () => import("@/components/commemoration"),
      LoveLetter: () => import("@/components/loveLetter"),
      NewYear: () => import("@/components/newYear"),
      Valentine: () => import("@/components/valentine"),

      templateType: "valentine",
      playingMusic:
        "https://636c-cloud1-7g144xneb715a224-1307109372.tcb.qcloud.la/music/一口甜-我超喜欢你.mp3",
      titleMap: {
        birthday: "生日祝福",
        commemoration: "恋爱纪念日",
        loveLetter: "浪漫情书",
        newYear: "新年祝福",
        valentine: "情人节祝福",
      },
      tip: false,
      play: true,

      count: 0,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.play = false;
        this.$refs.audio.pause();
      } else {
        setTimeout(() => {
          this.togglePlay();
        }, 1000);
      }
    });
    this.templateType = this.$route.params.type;
    this.$cloud
      .callFunction({
        name: "router",
        data: {
          url: "getInfo",
          params: {
            UUID: this.$route.params.UUID,
          },
        },
      })
      .then((res1) => {
        this.$cloud
          .getTempFileURL({
            fileList: [res1.result.data.music],
          })
          .then((res) => {
            console.log(res.fileList[0].tempFileURL);
            this.playingMusic = res.fileList[0].tempFileURL;
            setTimeout(() => {
              this.$refs.template.autoScroll();
            }, 1000);
          });
      });
  },
  methods: {
    gotoEdit() {
      this.$router.push(
        `/edit/${this.templateType}/1/${this.$route.params.UUID}`
      );
    },
    togglePlay() {
      this.play = !this.play;
      if (this.play) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.display {
  width: 100vw;
  height: calc(100vh - 15vw);
  position: relative;

  .back {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 99;

    .el-icon-arrow-left {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .icon {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 8px;
    right: 8px;
    filter: grayscale(1);
    z-index: 99;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid transparent;

    &.play {
      background-image: url("~@/assets/icon/playMusic.png");
      filter: none;
      animation: round 4s linear infinite;
    }

    &.pause {
      background-image: url("~@/assets/icon/pauseMusic.png");
    }
  }
  @keyframes round {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  .bottom {
    width: 100%;
    height: 85px;
    padding: 0 6vw 1vw;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    font-size: 3vw;
    z-index: 10;

    img {
      width: 6vw;
      height: 6vw;
      margin-right: 3vw;
    }

    .name {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .btn {
      height: 47%;
      border-radius: 100px;
      background: #eb5c69;
      padding: 0 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
</style>